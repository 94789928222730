import React from 'react';
import { Routes, Route } from "react-router-dom";
import './App.css';
// import Test from './Test';
import Badge from "./Badge";
import Donacija from './Donacija';
import TestemonialsHolder from './TestemonialsHolder';
// import Naslovnica from './Naslovnica';
import Navbar from "./Navbar"
// import Showcase from './Showcase';
import Footer from './Footer';
// import { useParams } from 'react-router-dom';
import About from './About';
import Home from './Home';
import Contact from './Contact';
import Privacy from './Privacy';
import Usluge from "./Usluge";

// import Partner from './Partner';
// import Podrska from './Podrska';
// import Privatnost from './Privatnost';
// import Zaposlenja from './Zaposlenja';
import Thyroid from "./Thyroid";
// import GR1 from "./GR1";
// import DL2 from "./DL2";
// import Registar from './Registar';

//175, 202, 187 temeljna pozadina


export default function App() {
  return (

    <div className="App">
      <Navbar />

      <Routes>
        <Route path='/Test' element={<Badge />}>  </Route>
        <Route path='/About' element={<About />}>  </Route>
        <Route path='/Contact' element={<Contact />}>  </Route>
        <Route path='/Privacy' element={<Privacy />}>  </Route>
        <Route path='/Usluge' element={<Usluge />}>  </Route>
        {/*<Route path='/Partner' element={<Partner />}>  </Route>
        <Route path='/Support' element={<Podrska />}>  </Route>
        <Route path='/Privacy' element={<Privatnost />}>  </Route>
        <Route path='/Careers' element={<Zaposlenja />}>  </Route>
        <Route path='/SPP' element={<SPP />}>  </Route>
        <Route path='/Registar' element={<Registar />}>  </Route>
        <Route path='/GR1' element={<GR1 />}>  </Route>
        <Route path='/DL2' element={<DL2 />}>  </Route> */}
        <Route path='*' element={<Home />}>  </Route>

      </Routes>
      <Footer />
    </div>

  );
}


