import Naslovnica from "./Naslovnica"
import Showcase from "./Showcase"

export default function About(props) {

    const myListString = '<ul><li>Item 1</li><li>Item 2</li><li>Item 3</li></ul>';

    function MyListComponent() {
      return (
        <div dangerouslySetInnerHTML={{ __html: myListString }} />
      );
    }
    
    // Usage
    <MyListComponent />;

    return (
        <>
            <Showcase naslov="Mi smo" opis="dr. Leila Perković i dr. Maja Magazin<br />specijalistice kliničke citologije sa dugogodišnjim iskustvom u ginekološkoj
             i općoj citologiji.<br><img src='img/linkedin.png' /> <a href='https://hr.linkedin.com/in/leila-perkovi%C4%87-79682090' target='_blank' rel='noopener noreferrer'> Leila</a><br>
             <img src='img/linkedin.png' /> <a href='https://hr.linkedin.com/in/maja-magazin-17b1a766' target='_blank' rel='noopener noreferrer'>Maja</a>" pictureFirst={true} image="microscope2.jpg" />
          
        </>
    )

}