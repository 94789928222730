export default function Badge(props) {

    function handleClick(e) {
        document.location.href=props.link;
      }

    return (

        <div className="col-lg-4">
        <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3 button" onClick={handleClick}>
            <div className="features-icons-icon d-flex"><i className={`m-auto text-primary ${props.icon}`} ></i></div>
            <h3>{props.naslov}</h3>
            <p className="lead mb-0">{props.opis}</p>
        </div>
    </div>
    )

}