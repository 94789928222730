
import Naslovnica from "./Naslovnica";
import Showcase from "./Showcase";
import BadgeHolder from "./BadgeHolder";
import Testemonial from "./Testemonial";
import TestemonialsHolder from "./TestemonialsHolder";
import Donacija from "./Donacija";


import * as globals from './globals';

export default function Home() {
    var imageFirst = true;

    function alternateImagePosition() {
        imageFirst = !imageFirst;
        return imageFirst;
    }

    return (
        <>
            <Showcase icon="logo2.png" naslov="" opis="Citološki laboratorij za analizu svih vrsta citoloških uzoraka" pictureFirst={alternateImagePosition()} image="sl.jpg" />

            {/* <Showcase naslov="Naše usluge" opis="Papa test, punkcija štitnjače, punkcija dojke, punkcija tumorskih tvorbi, citološka analiza urina, citološka analiza iscjetka dojke, citološka analiza brisa nosa ..." pictureFirst={alternateImagePosition()} image="microscopy.jpg" />

            <Showcase naslov="Jednostavnost i brzina" opis="" pictureFirst={alternateImagePosition()} image="microscope2.jpg" />

            {/* <Showcase naslov="Citološka punkcija pod kontrolom UZV-a" opis="Citološka punkcija pod kontrolom UZV-a jedna je od najvažnijih dijagnostičkih pretraga.
Ova minimalno invazivna, brza i pouzdana metoda nezaobilazna je u dijagnostici čvorastih i cistično-čvorastih promjena štitnjače sa svrhom dokaza zloćudnih  promjena kao što je rak štitnjače, čime se izbjegavaju nepotrebne operacije kod čvorova koji pokazuju dobroćudan karakter." pictureFirst={alternateImagePosition()} image="thyroid.jpg" /> */}

            {/* <Showcase naslov="Naše prednosti" opis="Višegodišnje poznavanje kliničkog rada u više hrvatskih vodećih bolnica" pictureFirst={alternateImagePosition()} image="workflow.jpg" /> */} 


            {/* <BadgeHolder title="Pretrage koje radimo" skip="---" /> */}

            {/* <TestemonialsHolder title="Što o našim uslugama kažu korisnici..." /> */}


        </>

    );
}