import * as globals from './globals';

export default function Navbar() {
    return (
        <nav className="navbar navbar-expand-md navbar-light bg-light shadow">
       <img src="%PUBLIC_URL%/../logo_mali.png" alt="logo" ></img><a className="navbar-brand" href="/">{globals.COMPANY_NAME.toUpperCase()}</a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav">
            <li className="nav-item active">
              <a className="nav-link" href="/">Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="about">O nama</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="usluge"> Usluge </a>
              {/* <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="/">Papa test</a>
                <a className="dropdown-item" href="/">Punkcija štitnjače</a>
                <a className="dropdown-item" href="/">Punkcija dojke</a>
                <a className="dropdown-item" href="/">Punkcija tumorskih tvorbi</a>
                <a className="dropdown-item" href="/">Citološka analiza urina</a>
                <a className="dropdown-item" href="/">Citološka analiza iscjetka dojke</a>
                <a className="dropdown-item" href="/">Citološka analiza brisa nosa</a>
              </div> */}
            </li>
            <li className="nav-item">
              <a className="nav-link" href="contact">Kontakt</a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link disabled" href="#">Disabled</a>
            </li> */}
          </ul>
          {/* <form className="form-inline my-2 my-lg-0">
            <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
            <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
          </form> */}
        </div>
        </nav>
  
    );
  }
  

  

