import Naslovnica from "./Naslovnica"
import Showcase from "./Showcase"

var imageFirst = true;

    function alternateImagePosition() {
        imageFirst = !imageFirst;
        return imageFirst;
    }

export default function Privacy(props) {

    return (
        <>
            <Showcase naslov="Zaštita osobnih podataka" opis="Cytoalpha poštuje privatnost svojih korisnika i obvezuje se na zaštitu njihovih osobnih podataka u skladu s Općom uredbom o zaštiti podataka (GDPR)" pictureFirst={alternateImagePosition()} image="shield.jpg" />

            <Showcase naslov="Koje podatke prikupljamo" opis="ime i prezime ispitanika i datum rođenja." pictureFirst={alternateImagePosition()} image="data.jpg" />

            <Showcase naslov="Za što ih koristimo" opis="Osobne podatke prikupljamo isključivo u cilju točne identifikacije uzoraka" pictureFirst={alternateImagePosition()} image="data-using.jpg" />

            <Showcase naslov="Kako čuvamo osobne podatke" opis= "Osobne podatke čuvamo na sigurnom mjestu, zaštićenom od neovlaštenog pristupa, upotrebe, otkrivanja, izmjene ili uništen" pictureFirst={alternateImagePosition()} image="safe.jpg" />
           
            <Showcase naslov="Kako dijelimo osobne podatke" opis="Osobne podatke ne dijelimo s trećim stranama" pictureFirst={alternateImagePosition()} image="data3.jpg" />
            <Showcase naslov="Kako nas kontaktirati" opis="Ukoliko imate bilo kakvih pitanja ili zahtjeva u vezi s zaštitom osobnih podataka, možete nas kontaktirati telefonom ili e-poštom prikazanima na dnu stranice" pictureFirst={alternateImagePosition()} image="contact.jpg" />

          
        </>
    )

}