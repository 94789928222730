import * as globals from './globals';

export default function Footer(props) {
    const linkStyle = "nav-link "
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    return (
        <footer className="footer navbar-light bg-light">
            <div className="container">


                <div className="row ">
                    <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 border-right">
                        <h4>{globals.COMPANY_NAME.toUpperCase()}</h4><br /><span class='text'>©2023-{currentYear}<br />Sva prava pridržana</span>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 pb-1 pt-1">
                        <div className="row">
                            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 border-right">
                                <nav className="">
                                    <ul className="list-unstyled">
                                        <li><a className={linkStyle} href="About">O nama</a></li>

                                    </ul>
                                </nav>
                            </div>
                            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 border-right">
                                <nav className="">
                                    <ul className="list-unstyled">
                                        <li><a className={linkStyle} href="Contact">Kontakt</a></li>

                                    </ul>
                                </nav>
                            </div>
                            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 border-right">
                                <nav>
                                    <ul className="list-unstyled">
                                        <li><a className={linkStyle} href="Privacy">Privatnost</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 border-right pt-2">
                        {/* <p className={linkStyle} >Slijedite nas</p>

                        <Link name="facebook" class="text-left" link="https://www.facebook.com/" />F
                        <Link name="twitter" class="float-start" link="https://www.twitter.com/" />
                        <Link name="instagram" class="float-start" link="https://www.instagram.com/" /> */}
                        <p className="text"><a target="_blank" href={'https://www.google.com/maps/place/' + globals.COMPANY_TOWN  + ', ' + globals.COMPANY_ADDRESS}><i className="bi bi-house-door"></i>{globals.COMPANY_ADDRESS}<br />{globals.COMPANY_TOWN}</a></p>
                        <p className="text"><a style={{textTransform:'none'}} href={'mailto:' + globals.COMPANY_MAIL} ><i className="bi bi-envelope-at"></i> {globals.COMPANY_MAIL}</a></p>

                    </div>

                </div>

            </div>
        </footer>
    )

    function Link(props) {
        return (
            <>
                <a target="_blank" className="nav-link invert" href={props.link} title={props.name} >
                    <img src={`img/${props.name}.png`} alt={props.name} className="img-responsive" /> {props.name}</a>
            </>
        )
    }



}