import Naslovnica from "./Naslovnica"
import * as globals from './globals';


export default function Contact(props) {
    const style = { backgroundImage: `url('/img/contact.jpg')` }

    return (
        <>
            <section className="showcase">
                
                    <div className="row">
                        <div className={`col-lg-6 text-white showcase-img order-lg-1`} style={style} ></div>
                        <div className={`col-lg-6 showcase-text order-lg-2 center`}>     
                            <h2><i className="bi bi-envelope-at"></i>Mail</h2>
                            <p className="lead mb-0 "> <a style={{textTransform:'none'}} href={'mailto:' + globals.COMPANY_MAIL} >{globals.COMPANY_MAIL}</a></p>
                            <p className="lead mb-0 "><br></br></p>
                            <h2><i className="bi bi-phone-vibrate"></i>Telefon</h2>
                        
                            <p className="lead mb-0 "><a href={'tel:' + globals.MAJA_TEL} >{globals.MAJA_TEL}</a></p>
                            <p className="lead mb-0 "><a href={'tel:' + globals.LEILA_TEL} >{globals.LEILA_TEL}</a></p>
                           
                            <p className="lead mb-0 "><br></br></p>
                            <h2><i className="bi bi-house-door"></i>Adresa</h2>
                            <p className="lead mb-0 "><a target="_blank" href={'https://www.google.com/maps/place/' + globals.COMPANY_TOWN  + ', ' + globals.COMPANY_ADDRESS}> {globals.COMPANY_ADDRESS}<br></br> {globals.COMPANY_TOWN}</a> </p>
                           
                           

                        </div>
                    </div>
                    <div className="row g-0">
                        <div className={`col-lg-12`}>
                            <div class="mapouter">
                                <div class="gmap_canvas">
                                    <iframe width="100%" height="600" id="gmap_canvas" src={"https://maps.google.com/maps?q=" + globals.COMPANY_TOWN  + ', ' + globals.COMPANY_ADDRESS + "&t=&z=15&ie=UTF8&iwloc=&output=embed"} frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
              
            </section>



        </>
    )




}