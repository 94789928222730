import Naslovnica from "./Naslovnica"
import Showcase from "./Showcase"

export default function Usluge(props) {

    return (
        <>
            <Showcase textAlign='left' naslov="Naše usluge" opis="<p><li>citološka analiza PAPA razmaza</li><li>citološka analiza aspiracijskih uzoraka (dojka, štitnjača, limfni čvor, tumorske tvorbe..)</li>
            <li>citološka analiza eksfolijativnih uzoraka (urin, pleuralni izljev, ascites, eksprimat dojki, bris nosa, sputum)</li><li>FNA punkcije uz kontrolu UZV-a</li></p>" pictureFirst={false} image="thyroid.jpg" />
          
        </>
    )

}