
export default function Showcase(props) {
    const style = { backgroundImage: `url('/img/${props.image}')` }
    let p = (props.pictureFirst ) ? "0" : "2"
    let textAlign = props.textAlign; 


    return (
        <section className="showcase">
                <div className="row">
                    <div className={`col-lg-6 text-white showcase-img order-lg-${p}`} style={style}></div>
                    <div className={`col-lg-6 showcase-text order-lg-{p} mt-5  `} >
                        <h2>{props.icon && (<img src={ props.icon} alt={props.icon} width='300px' />)} {props.naslov} {props.tip}</h2>
                        <p style={{ textAlign: textAlign }} className="lead mb-0 ml-4" dangerouslySetInnerHTML={{__html: props.opis}}></p>
                    </div>
                </div>
        </section>

    );
}
